"use client";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import logo from "@/public/images/logo site.png";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getCookie } from "cookies-next";
import AUTH, { SelectLoggedUser } from "@/redux/Auth";
import UserLoggedMenu from "./UserLoggedMenu";
import defaultImage from "@/public/images/default_image.jpg";
import menu from "@/public/images/menu.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MyLink from "../myLink/MyLink";
import { useRouter } from "next/navigation";
import { toast } from "react-toastify";
import { ChevronDown } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const Index = ({ cartPage = false }: { cartPage?: boolean }) => {
  const [showMenu, setshowMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(SelectLoggedUser);
  const router = useRouter();
  useEffect(() => {
    const fetchData = async () => {
      const token = getCookie("Education_User_token") as string | null;
      if (token) {
        await dispatch(AUTH.thunks.doGetProfile({}));
      }
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const linksText = {
    home: "الرئيسيه",
    subjects: "المواد",
    instructors: "المدرسين",
    books: "الكتب",
    courses: "الحصص",
    fullExams: "الامتحانات الشاملة",
    myCourses: "كورساتي",
  };

  const userInitials = loggedUser.userData.name
    ? loggedUser.userData.name
        .split(" ")
        .slice(0, 2)
        .map((n: string) => n[0])
        .join("")
    : "U";

  return (
    <header>
      <div className={`fixed top-0 left-0  z-50 w-full py-5`}>
        <div className="lg:container">
          <div className="container flex items-center justify-between gap-3 relative bg-[#252F39bf] backdrop-blur-lg rounded-full py-6">
            <div className="flex items-center gap-10 grow">
              <MyLink href={"/"} className="font-[700] text-primaryColor">
                <Image src={logo} alt="logo" />
              </MyLink>
              {!showMenu && (
                <ul className="hidden lg:flex items-center gap-5">
                  <li>
                    <MyLink href={"/"} className="font-[500] text-white">
                      {linksText.home}
                    </MyLink>
                  </li>
                  <li>
                    <MyLink
                      href={"/subjects"}
                      className="  font-[500] text-white"
                    >
                      {linksText.subjects}
                    </MyLink>
                  </li>
                  <li>
                    <MyLink
                      href={"/instructors"}
                      className="  font-[500] text-white"
                    >
                      {linksText.instructors}
                    </MyLink>
                  </li>
                  {/* <li>
									<MyLink href={'/books'} className='  font-[500] text-white'>
										{linksText.books}
									</MyLink>
								</li> */}
                  <li>
                    <MyLink
                      href={"/courses"}
                      className="  font-[500] text-white"
                    >
                      {linksText.courses}
                    </MyLink>
                  </li>
                  <li>
                    <MyLink
                      href={"/fullExams"}
                      className="  font-[500] text-white"
                    >
                      {linksText.fullExams}
                    </MyLink>
                  </li>
                  {loggedUser.isLoggedIn && (
                    <li>
                      <MyLink
                        href={"/myCourses"}
                        className="  font-[500] text-white"
                      >
                        {linksText.myCourses}
                      </MyLink>
                    </li>
                  )}
                </ul>
              )}
            </div>
            {loading ? (
              <SkeletonTheme baseColor="#474A4D" highlightColor="#6b6e72">
                <div className="grid grid-cols-2 gap-2 w-[180px]">
                  <Skeleton height={37} width="100%" />
                  <Skeleton height={37} width="100%" />
                </div>
              </SkeletonTheme>
            ) : loggedUser.isLoggedIn &&
              loggedUser.userToken &&
              loggedUser.userData &&
              loggedUser.userData.name ? (
              <>
                <div
                  className="flex items-center gap-2 cursor-pointer mr-auto"
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                >
                  <Avatar className="w-[30px] h-[30px] md:w-[47px] md:h-[47px]">
                    <AvatarImage
                      src={loggedUser.userData.image}
                      alt={loggedUser.userData.name}
                    />
                    <AvatarFallback>{userInitials}</AvatarFallback>
                  </Avatar>
                  <div className="flex-col gap-1 hidden md:flex">
                    <p className="font-[500] text-white text-right text-xs md:text-lg">
                      {loggedUser.userData.name &&
                        loggedUser.userData.name
                          .split(" ")
                          .slice(0, 2)
                          .join(" ")}
                    </p>
                    <p className="bg-primaryColor rounded-full px-2 w-fit text-sm">
                      {loggedUser.userData.money
                        ? `${loggedUser.userData.money} جنية`
                        : "0 جنية"}
                    </p>
                  </div>
                  <ChevronDown className="text-[#BEC4E0]" />
                </div>
                {showProfileMenu && (
                  <UserLoggedMenu
                    dashboard={false}
                    loggedUser={loggedUser}
                    setShowProfileMenu={setShowProfileMenu}
                    showProfileMenu={showProfileMenu}
                  />
                )}
              </>
            ) : (
              <div className="flex items-center gap-2">
                <MyLink
                  href={"/login"}
                  className="text-[10px] text-center md:text-[13px] font-[500] text-white py-2 px-2 border border-white rounded-[7px] bg-gradient-to-b from-[#32353827] to-[#32353866]"
                >
                  تسجيل دخول
                </MyLink>
                <MyLink
                  href={"/signup"}
                  className="text-[10px] text-center md:text-[13px] font-[500] text-[#202225] bg-primaryColor rounded-[7px] py-2 px-2"
                >
                  انشاء حساب
                </MyLink>
              </div>
            )}
            <Image
              src={menu}
              alt="menu"
              className=" lg:hidden cursor-pointer w-[50px]"
              onClick={() => setshowMenu(!showMenu)}
              width={50}
            />
            <div
              className={` fixed top-0 right-0 p-5 w-[100%] h-screen  z-50 ${
                showMenu ? " translate-x-0" : " translate-x-[200%]"
              } transition-all duration-300  `}
            >
              <div
                className={` fixed top-0 right-0 p-5 w-[70%] md:w-[50%] h-screen bg-[#202225] z-50 ${
                  showMenu ? " translate-x-0" : " translate-x-[200%]"
                } transition-all duration-300  `}
              >
                <MyLink
                  href={"/"}
                  className="font-[700] text-primaryColor"
                  onClick={() => setshowMenu(false)}
                >
                  <Image src={logo} alt="logo" />
                </MyLink>
                <ul className=" flex flex-col  gap-5 mt-5">
                  <li onClick={() => setshowMenu(false)}>
                    <MyLink href={"/"} className="   font-[500] text-white">
                      {linksText.home}
                    </MyLink>
                  </li>
                  <li onClick={() => setshowMenu(false)}>
                    <MyLink
                      href={"/subjects"}
                      className="  font-[500] text-white"
                    >
                      {linksText.subjects}
                    </MyLink>
                  </li>
                  <li onClick={() => setshowMenu(false)}>
                    <MyLink
                      href={"/instructors"}
                      className=" font-[500] text-white"
                    >
                      {linksText.instructors}
                    </MyLink>
                  </li>
                  {/* <li onClick={() => setshowMenu(false)}>
									<MyLink href={'/books'} className=' font-[500] text-white'>
										{linksText.books}
									</MyLink>
								</li> */}
                  <li onClick={() => setshowMenu(false)}>
                    <MyLink
                      href={"/courses"}
                      className=" font-[500] text-white"
                    >
                      {linksText.courses}
                    </MyLink>
                  </li>
                  <li onClick={() => setshowMenu(false)}>
                    <MyLink
                      href={"/fullExams"}
                      className=" font-[500] text-white"
                    >
                      {linksText.fullExams}
                    </MyLink>
                  </li>
                  {loggedUser.isLoggedIn && (
                    <li onClick={() => setshowMenu(false)}>
                      <MyLink
                        href={"/myCourses"}
                        className=" font-[500] text-white"
                      >
                        {linksText.myCourses}
                      </MyLink>
                    </li>
                  )}
                </ul>
              </div>
              {showMenu && (
                <div
                  className=" fixed top-0 left-0 w-full h-screen bg-[#ff1414] z-30 opacity-0"
                  onClick={() => setshowMenu(false)}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className=" h-[95px]"></div>
    </header>
  );
};

export default Index;
